var $class="se2--application-chat-popup",$name="ApplicationChatPopup",$path="app/components/ApplicationChatPopup/index.js",$this={$class,$name,$path,};import activateLiveChat from 'app/modules/liveChat';
import shell from 'app/modules/shell';

const chatSectionActiveAttribute = 'data-chat-section-active';

export default shell.registerComponent($this, ({ configs, mount }) => {
  mount((element) => {
    if (element.dataset && element.hasAttribute(chatSectionActiveAttribute)) {
      element.classList.remove(configs.css.classNames.hide);
      activateLiveChat(element);
    }
  });
});

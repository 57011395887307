var $name="liveChat/index",$path="app/modules/liveChat/index.js",$this={$name,$path,};import shell from 'app/modules/shell';

import appendAsyncScriptElement from 'app/utilities/appendAsyncScriptElement';

import { css, delays, messages } from 'configs';

import forEach from 'lodash/forEach';

import analytics from '../analytics';

/* eslint-disable no-underscore-dangle */
// Salesforce documentation: Automated Chat Invitation Code Sample
// eslint-disable-next-line max-len
// https://developer.salesforce.com/docs/atlas.en-us.212.0.live_agent_dev.meta/live_agent_dev/live_agent_automated_chat_invitations_code_sample.htm

// Some information about implementing the chat:
// https://schneider.atlassian.net/wiki/spaces/STUM/pages/21366043/How+to+configure+Chat
// https://schneider.atlassian.net/wiki/spaces/SDLRF/pages/1575851308/Chat+templates

const chatElements = new Set();

const buttonIdPrefix = 'btn';
const liveagentOfflinePrefix = 'liveagent_offline_';
const liveagentOnlinePrefix = 'liveagent_online_';
const rejectIdPrefix = 'reject';
const windowHeight = 600;
const windowWidth = 500;

let loaded = false;
let rejectChatButton = null;
let startChatButton = null;
let timerId = null;
let willChatScriptBeLoadedForcibily = false;
let willChatScriptBeLoadedWithDelay = false;

export default shell.registerModule($this, ({ addEventListener, log, publishMessage }) => {
  const getLiveAgentDetails = ({
    chatCountryCode,
    chatCountryName,
    chatReferringSiteName,
  }) => ([
    {
      displayToAgent: true,
      entityName: 'ReferringSiteName__c',
      label: 'Source Site Name',
      value: chatReferringSiteName,
    },
    {
      displayToAgent: false,
      entityName: 'CountryCode__c',
      label: 'Country Code',
      value: chatCountryCode,
    },
    {
      displayToAgent: true,
      entityName: 'ReferringPage__c',
      label: 'Referring Page',
      value: document.title,
    },
    {
      displayToAgent: true,
      entityName: 'ReferringPageURL__c',
      label: 'Referring Page',
      value: document.URL,
    },
    {
      displayToAgent: true,
      entityName: 'SuppliedCountry__c',
      label: 'Web Country',
      value: chatCountryName,
    },
  ]);

  const checkChatAvailability = (element) => {
    if (!element) return;

    const observer = new MutationObserver(([mutationRecord]) => {
      const { oldValue } = mutationRecord;
      const isChatAvailable = oldValue.includes('display: none') && element.style.display !== 'none';

      if (isChatAvailable) {
        analytics.trackChatAction({ chatActionKey: 'Opened' });
      }

      publishMessage(
        messages.embeddedChat,
        {
          state: isChatAvailable ? 'available' : 'unavailable',
        },
      );
    });

    observer.observe(
      element,
      {
        attributes: true,
        attributeFilter: ['style'],
        attributeOldValue: true,
      },
    );
  };

  const showChatPopup = (event, element, { BUTTON_EVENT }) => {
    // Because of Live Agent bug we should not show chat popup if user is on other tab (as on prod).
    // Link to the bug: https://success.salesforce.com/ideaView?id=08730000000DrElAAK
    if (event === BUTTON_EVENT.BUTTON_AVAILABLE && !document.hidden) {
      // Show chat pop up
      element.classList.add(css.classNames.open);
      analytics.trackChatAction({ chatActionKey: 'Opened' });
    } else {
      // Hide chat pop up if
      // event === window.liveagent.BUTTON_EVENT.BUTTON_UNAVAILABLE
      // event === window.liveagent.BUTTON_EVENT.BUTTON_ACCEPTED
      // event === window.liveagent.BUTTON_EVENT.BUTTON_REJECTED
      element.classList.remove(css.classNames.open);
    }
  };

  const addChatElement = (liveagent, element) => {
    // Enable Live Agent advanced logging to be available through the Browser's Developer Console
    liveagent.enableLogging();

    const { dataset } = element;
    const { chatButtonId, chatTimerId } = dataset;

    liveagent.setChatWindowHeight(windowHeight);
    liveagent.setChatWindowWidth(windowWidth);

    const liveAgentDetails = getLiveAgentDetails(dataset);
    forEach(
      liveAgentDetails,
      ({ displayToAgent, entityName, label, value }) => {
        liveagent
          .addCustomDetail(label, value, displayToAgent)
          .saveToTranscript(entityName);
      },
    );

    if (chatTimerId) {
      /*
        * If chatTimerId is equal to '1' the delay before popup appears should be 20 seconds
        * If chatTimerId is equal to '2' the delay before popup appears should be 45 seconds
        * If chatTimerId is equal to '3' the delay before popup appears should be 120 seconds
        * */

      // eslint-disable-next-line max-len
      // https://developer.salesforce.com/docs/atlas.en-us.212.0.live_agent_dev.meta/live_agent_dev/live_agent_automated_chat_invitations_API_setCustomVariable.htm
      liveagent.setCustomVariable('TimerConfigId', chatTimerId);

      // eslint-disable-next-line max-len
      // https://developer.salesforce.com/docs/atlas.en-us.212.0.live_agent_dev.meta/live_agent_dev/live_agent_chat_buttons_API_addButtonEventHandler.htm
      liveagent.addButtonEventHandler(
        chatButtonId,
        (event) => {
          showChatPopup(event, element, liveagent);
        },
      );
    } else {
      if (!window._laq) {
        window._laq = []; // window._laq is used by salesforce script
      }

      checkChatAvailability(element.querySelector(`#${liveagentOnlinePrefix}${chatButtonId}`));

      window._laq.push(() => {
        liveagent.showWhenOnline(
          chatButtonId,
          element.querySelector(
            `#${liveagentOnlinePrefix}${chatButtonId}`,
          ),
        );
        liveagent.showWhenOffline(
          chatButtonId,
          element.querySelector(
            `#${liveagentOfflinePrefix}${chatButtonId}`,
          ),
        );
      });
    }

    if (liveagent.startChat) {
      startChatButton = element.querySelector(
        `#${buttonIdPrefix}${chatButtonId}`,
      );

      addEventListener(startChatButton, 'click', () => {
        liveagent.startChat(chatButtonId);
        analytics.trackChatAction({ chatActionKey: 'Launched' });
      });
    }

    if (liveagent.rejectChat) {
      rejectChatButton = element.querySelector(
        `#${rejectIdPrefix}${chatButtonId}`,
      );

      addEventListener(rejectChatButton, 'click', () => {
        liveagent.rejectChat(chatButtonId);
        analytics.trackChatAction({ chatActionKey: 'Closed' });
      });
    }
  };

  const initAgent = (element) => {
    const { liveagent } = window;
    const {
      chatInstanceCode,
      chatLiveAgentInitUrl,
      chatWebsiteCode,
    } = element.dataset;

    if (liveagent && liveagent.init) {
      liveagent.init(
        chatLiveAgentInitUrl,
        chatWebsiteCode,
        chatInstanceCode,
      );

      log.info('Liveagent was initialized for: %o.', element);
      addChatElement(liveagent, element);
    }
  };

  const activateLiveChat = (element, force = false) => {
    if (!element?.dataset || chatElements.has(element)) return;

    chatElements.add(element);

    if (!loaded || !willChatScriptBeLoadedForcibily) {
      if (force) {
        willChatScriptBeLoadedForcibily = true;
        willChatScriptBeLoadedWithDelay = false;
        // Clear timeout if it was set before
        if (timerId) clearTimeout(timerId);
      } else {
        // No need to set timer with delay again
        // if it was done before by some component
        if (willChatScriptBeLoadedWithDelay) return;
        willChatScriptBeLoadedWithDelay = true;
      }

      const { dataset: { chatJsUrl } } = element;
      timerId = setTimeout(
        () => {
          appendAsyncScriptElement(chatJsUrl)
            .then(
              () => {
                loaded = true;
                chatElements.forEach(initAgent);
              },
              () => {
                log.error('Failed to load script', chatJsUrl);
              },
            );
        },
        force ? 0 : delays.s30,
      );
    }
  };

  return activateLiveChat;
});
